@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&display=swap');

:root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
}

.icon {
  color: var(--primary-dark);
}

@layer base {
  body {
    @apply font-[Poppins]
  }

  li {
    @apply p-4
  }

  h1 {
    @apply text-3xl md:text-4xl font-bold
  }

  h2 {
    @apply text-3xl font-bold
  }

  h3 {
    @apply text-xl font-bold
  }
  button {
    @apply p-3 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-md
  }

  button-s {
    @apply bg-green-600 w-[150px] rounded-md my-6 mx-auto px-6 py-3 text-white font-bold
  }

  .icon {
    @apply text-2xl cursor-pointer
  }

  .shim-green {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 255, 0, 0.7);
  }
  .shim-green::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%
    );
    animation: shimmer 2s ease-out infinite;
    content: "";
  }
  
  .shim-blue {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 155, 255, 0.7);
  }
  .shim-blue::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%
    );
    animation: shimmer 2.5s ease-out infinite;
    content: "";
  }
  
  .shim-red {
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 0, 0, 0.7);
  }
  .shim-red::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%
    );
    animation: shimmer 3s ease-out infinite;
    content: "";
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(0%);
      opacity: 0;
    }
  }
}